/* :root {
    --temp: #80ffdb;
    --temp2: #64dfdf;
    --violet-secondary: #2668bec5;
    --text-primary: #fff;
    --text-secondary: #777;
    --background-dark: #cee9ee; */
/* --background: #27234b; */
/* --background: #252525;
    --secondary: #592f9e;
    --text-color: #fff;
} */

:root {
  --background-dark: #27234b;
  --purple-pain: #8458b3;
  --medium-purple: #d0bdf4;
  --freeze-purple: #e5eaf5;
  --ice-cold: #a0d2eb;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: var(--background-dark);
  background: url(media/bg-image.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* color: var(--text-primary); */
  color: var(--text-color);
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  /* padding: 1rem 1.5rem; */
}

.App {
  height: 100vh;
  width: 100%;
  position: relative;
}

.links,
.links:hover,
.links:active,
.links:visited {
  color: #fff;
  text-decoration: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: rgba(0, 0, 0, 0);
  padding: 1px 1rem;
}

#app-logo {
  flex: 2;
}

.header_heading {
  height: 90%;
  margin-left: 3%;
  font-family: "ZCOOL KuaiLe", cursive;
  /* color: #66bfbf; */
  font-size: 3.5rem;
  flex: 2.5;
}

.header_heading:hover {
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
}

.header_links {
  /* border: 1px solid red; */
  /* margin: 10px; */
  flex: 0.5;
  justify-self: center;
  align-self: center;
  text-align: center;
}

.main-container {
  height: 100vh;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-navigation {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.topics-section {
  /* margin: 1rem;
    margin-top: 1rem; */
  /* display: flex;
  flex: 1 1 200px;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  grid-gap: 40px;
  padding: 4rem;
}

.topics-section > * {
  justify-self: center;
  align-self: center;
  /* margin: 10px;
    padding: 10px; */
}

.topics-section:last-child {
  justify-self: flex-start;
  align-self: flex-start;
}

/* .topppp {
  transition: all 0.2s ease-in-out;
  opacity: 0.7;
} */
/* .topppp:hover {
  transform: scale3d(1.1, 1.1, 1.1);
  opacity: 1;
} */
/* VIDEO BACKGROUND */
.showcase video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.8;
  z-index: -1;
}

/* Pagination */
.pagination {
  width: 100%;
  height: 50px;
}

.center-col {
  border: solid;
  border-radius: 5px;
  border-width: 1px;
  flex: 1;
  padding: 10px;
  overflow-y: scroll;
}
