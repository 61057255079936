.module-border-wrap {
  width: 100%;
  height: 250px;
  padding: 3px;
  background: linear-gradient(to right, #ebdad0, #952c99a2);
  /* background: linear-gradient(to right, #da70d6, #ffa500); */
}

.module {
  background: #222;
  color: white;
  height: 240px;
  padding: 1rem;
}
